<script setup>
import { computed, ref } from 'vue'
import Message from './components/Message.vue'
import { config } from '@dpgradio/creative'
const parameters = new URLSearchParams(window.location.search)
const theme = parameters.get('theme') || config('theme')
const messages = ref([])

const fetchMessages = async () => {
  const response = await fetch(
    `https://${config('api_base_url')}/2.4/messages/tagged_with/${parameters.get('tagId')}?api_key=${parameters.get(
      'token'
    )}`
  )
  messages.value = (await response.json()).messages
}

const filteredMessages = computed(() => {
  return messages.value?.slice(0, 6)
})

setInterval(() => {
  fetchMessages()
}, 10000)

fetchMessages()
</script>

<template>
  <div v-auto-animate class="wall" :class="theme">
    <Message :is="message.component" v-for="message in filteredMessages" :key="message.id" :message="message" />
  </div>
</template>

<style lang="scss" scoped>
.wall {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(10, calc(10% - 4px));
  grid-gap: 15px;
  flex-wrap: wrap;
  overflow: hidden;
}
</style>
