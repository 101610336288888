<template>
  <div :class="classes">
    <div class="message__content">
      <div class="message__media">
        <img v-if="hasImage" :src="props.message.media.media_url" alt="Message image" />
        <video v-else-if="hasVideo" :src="props.message.media.media_url" autoplay muted loop />
        <h1 v-else>{{ props.message.message }}</h1>
      </div>
      <div class="message__header">
        <div class="message__author">{{ props.message.username }}</div>
        <relative-time :datetime="props.message.sent_at" class="message__timestamp">
          {{date}}
        </relative-time>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import * as RelativeTimeElement from "@github/relative-time-element"

const props = defineProps(['message'])
const date = new Date(props.message.sent_at).toLocaleDateString()

const hasMedia = props.message.media
const hasImage = props.message.media && props.message.media.type === 'image'
const hasVideo = props.message.media && props.message.media.type === 'video'
const classes = {
  message: true,
  'message--media': hasMedia && hasImage,
}
</script>

<style lang="scss">
.message {
  background-color: transparent;
  color: var(--accent-color);
  font-family: var(--font-title);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-title);
  container-type: inline-size;

  &__content {
    background-color: var(--base-color);
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    height: 30px;
    padding: 0.5rem 1rem;
  }

  &__media {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 40px);

    img,
    video {
      border-radius: 0.5rem 0.5rem 0 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 16 / 9;
    }

    h1 {
      padding: 1rem 1.5rem;
      display: flex;
      flex-direction: column;
      font-size: 2.7rem;
      text-wrap: balance;
      overflow-wrap: break-word;
      hyphens: auto;
      width: 100%;
    }
  }

  @container (max-width: 500px) {
    background-color: blue;

    h1 {
      font-size: 2rem;
    }
  }

  &:nth-of-type(1) {
    grid-area: 1 / 1 / 6 / 3;
  }

  &:nth-of-type(2) {
    grid-area: 1 / 3 / 5 / 4;
  }

  &:nth-of-type(3) {
    grid-area: 1 / 4 / 5 / 5;
  }

  &:nth-of-type(4) {
    grid-area: 6 / 1 / 10 / 2;
  }

  &:nth-of-type(5) {
    grid-area: 6 / 3 / 10 / 2;
  }

  &:nth-of-type(6) {
    grid-area: 5 / 3 / 10 / 5;
  }
}
</style>
