import { createApp } from 'vue'
import { configuration } from '@dpgradio/creative'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import './assets/css/index.scss'
import App from './App.vue'

const initialize = async () => {
  const parameters = new URLSearchParams(window.location.search)
  await configuration.retrieveConfigForStation(parameters.get('station'))

  const app = createApp(App)

  app.use(autoAnimatePlugin).mount('#app')
}

initialize()
